@import '../../styles/breakpoints.scss';

.Root {
  --border: 1px;
  --radius: 100px;
  --size: var(--font-size-button-1);
  --color: var(--color-white);
  --border-background: linear-gradient(
    106.95deg,
    #ffffff 13.24%,
    rgba(255, 255, 255, 0) 29.86%,
    rgba(255, 255, 255, 0) 60.25%,
    #ffffff 86.44%
  );

  &.NoBorder {
    --border-background: none;
    --border-background-hover: none;
    border: 0;
  }

  &.BigButton {
    width: 70%;
    --border-background: linear-gradient(
      106.95deg,
      #ffffff 46.24%,
      rgba(255, 255, 255, 0) 55.86%,
      rgba(255, 255, 255, 0) 60.25%,
      #ffffff 66.44%
    );
  }

  --border-background-hover: linear-gradient(
    293.99deg,
    #ffffff 10.27%,
    rgba(255, 255, 255, 0) 27.16%,
    rgba(255, 255, 255, 0) 58.06%,
    #ffffff 84.68%
  );
}
.Root.Small {
  --size: var(--font-size-button-2);
}

.Root.CenterText {
  justify-content: center;
}

.Root.TextAlignCenter {
  text-align: center;
}

.Root.NarrowIconGap {
  gap: 12px;
}

.Root.ShuffleDesktop {
  display: flex;
  font-size: var(--font-size-button-2);
  align-items: center;
  justify-content: center;
  gap: var(--spacing-m);
}

.Root.ShuffleMobile {
  font-size: var(--font-size-button-2);
  max-width: max-content;
  grid-column: 2 / 6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-m);
  width: 100%;

  @media (min-width: $breakpoint-m) {
    grid-column: 2 / 14;
  }
}

.Root {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: var(--button-padding);
  border-radius: 100px;
  z-index: 0;
  border: none;
  color: var(--color);
  cursor: pointer;
  font-size: var(--size);
  overflow: hidden;
  letter-spacing: 0.3px;
  background-image: var(--gradient-pink-blue);
}

.Root.InlineFlex {
  padding: var(--button-padding-with-icon);
}

.Root:hover:before {
  opacity: 0;
}
.Root:hover:after {
  opacity: 1;
}

.Root:before {
  content: '';
  display: block;
  background-image: var(--gradient-pink-blue);
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  transition: opacity 0.8s ease;
}

.Root:after {
  content: '';
  display: block;
  background-image: var(--gradient-pink-blue-hover);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  transition: opacity 0.8s ease;
}

.Root.IconHoverDown .Icon {
  transition: transform 0.2s ease;
}

.Root.IconHoverDown:hover .Icon {
  transform: translateX(5px);
}

.IconRotate45 .Icon {
  rotate: 90deg;
}

.PurpleBlue.Root:before,
.PurpleBlue.Root {
  background-image: var(--gradient-purple-blue);
}

.PinkBlue.Root:before,
.PinkBlue.Root {
  background-image: var(--gradient-pink-blue);
}

.PinkOrange.Root:before,
.PinkOrange.Root {
  background-image: var(--gradient-pink-orange);
}

.PinkPurple.Root:before,
.PinkPurple.Root {
  background-image: var(--gradient-pink-purple);
}

.BlueGreen.Root:before,
.BlueGreen.Root {
  background-image: var(--gradient-blue-green);
}

.PurpleBlue.Root:after {
  background-image: var(--gradient-purple-blue-hover);
}

.PinkBlue.Root:after {
  background-image: var(--gradient-pink-blue-hover);
}

.PinkOrange.Root:after {
  background-image: var(--gradient-pink-orange-hover);
}

.PinkPurple.Root:after {
  background-image: var(--gradient-pink-purple-hover);
}

.BlueGreen.Root:after {
  background-image: var(--gradient-blue-green-hover);
}

.Root.Circle {
  padding: 1.5rem;
}

.InlineFlex {
  display: inline-flex;
  gap: 16px;
  align-items: center;
}

.Bg {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  border-radius: var(--radius);
  padding: var(--border); /* the thickness of the border */
  /* the below will do the magic */
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
    /* this will cover only the content area (no padding) */
      linear-gradient(#fff 0 0); /* this will cover all the area */
  -webkit-mask-composite: xor; /* needed for old browsers until the below is more supported */
  mask-composite: exclude; /* this will exclude the first layer from the second so only the padding area will be kept visible */

  .NoBorder & {
    display: none;
  }
}

.Bg:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 110%;
  height: 200%;
  background: var(--border-background);
  transform: translate(-46%, -50%) rotate(8deg);
  transition: transform 0.9s ease;

  .BigButton & {
    width: 400%;
    height: 500px;
    background: var(--border-background);
    transform: translate(-43%, -37%) rotate(-153deg);
  }
}

.Root:hover .Bg:before {
  transform: translate(-50%, -50%) rotate(187deg);
}

.Root.BigButton:hover .Bg:before {
  transform: translate(-55%, -47%) rotate(0deg);
}

.Disabled.Root {
  --border-background: var(--color-grey-500);
  --border-background-hover: var(--color-grey-500);
  --color: var(--color-grey-500);
  cursor: default;
}
