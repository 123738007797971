@import '../../styles/breakpoints.scss';

.Root {
  --border: 1px;
  --radius: 100px;
  --size: var(--font-size-button-1);
}

.NoBorderRadius {
  --radius: 0;
}

.Root.Small {
  --size: var(--font-size-button-2);
}

.Root.NavDesktop {
  --size: var(--font-size-button-2);
  padding: 1rem 2.8rem;
}

.Root.BlackText {
  color: var(--color-black-800);
}

.Root.MediaBank {
  grid-column: 2 / 5;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;

  @media (min-width: $breakpoint-m) {
    grid-column: 3 / 6;
  }
}
.Root.MediaBank .Icon {
  width: 15px;
  height: 15px;
}
.Root.BorderWhite {
  --color: var(--color-white);
  --border-background: linear-gradient(
    106.95deg,
    #ffffff 13.24%,
    rgba(255, 255, 255, 0) 29.86%,
    rgba(255, 255, 255, 0) 60.25%,
    #ffffff 86.44%
  );
  --border-background-hover: linear-gradient(
    293.99deg,
    #ffffff 10.27%,
    rgba(255, 255, 255, 0) 27.16%,
    rgba(255, 255, 255, 0) 58.06%,
    #ffffff 84.68%
  );
}

.Root.BorderBlack {
  --color: var(--color-black);
  --border-background: linear-gradient(
    106.95deg,
    #181818 13.24%,
    rgba(24, 24, 24, 0) 29.86%,
    rgba(24, 24, 24, 0) 60.25%,
    #181818 86.44%
  );
  --border-background-hover: linear-gradient(
    293.99deg,
    #181818 10.27%,
    rgba(24, 24, 24, 0) 27.16%,
    rgba(24, 24, 24, 0) 58.06%,
    #181818 84.68%
  );
}

.Root {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: var(--button-padding);
  border-radius: 100px;
  background: transparent;
  z-index: 0;
  border: none;
  color: var(--color);
  cursor: pointer;
  font-size: var(--size);
}

.NoBorderRadius {
  border-radius: 0;
}

.NoBorderRadiusPadding {
  padding: 1rem 1.6rem;
}

.FullWidth {
  width: 100%;
}

.Root.InlineFlex {
  padding: var(--button-padding-with-icon);
}

.Root.Circle {
  padding: 1.5rem;
}

.InlineFlex {
  display: inline-flex;
  gap: 16px;
  align-items: center;
}

.Bg {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  border-radius: var(--radius);
  padding: var(--border); /* the thickness of the border */
  /* the below will do the magic */
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
    /* this will cover only the content area (no padding) */
      linear-gradient(#fff 0 0); /* this will cover all the area */
  -webkit-mask-composite: xor; /* needed for old browsers until the below is more supported */
  mask-composite: exclude; /* this will exclude the first layer from the second so only the padding area will be kept visible */
}

.Bg:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 110%;
  height: 200%;
  background: var(--border-background);
  transform: translate(-46%, -50%) rotate(8deg);
  transition: transform 0.9s ease;
}

.Root:hover .Bg:before,
.Root.ProgrammaticHover .Bg:before {
  transform: translate(-50%, -50%) rotate(187deg);
}

@media (min-width: $breakpoint-m) {
  .IconRotate45 .Icon {
    rotate: 90deg;
  }
}

.Root.Share .Icon {
  rotate: 00deg;
}

.Root.IconHoverDown .Icon {
  transition: transform 0.4s ease;
}

.Root.IconHoverDown:hover .Icon,
.Root.ProgrammaticHover .Icon {
  transform: translateX(5px);
}

.Disabled.Root {
  --border-background: var(--color-grey-500);
  --border-background-hover: var(--color-grey-500);
  --color: var(--color-grey-500);
  cursor: default;
}
