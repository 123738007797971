.Root {
  background-color: #000;
  border: 3px solid #fff;
  z-index: 9999;
  position: absolute;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: var(--font-size-button-1);
  letter-spacing: 0.3px;
  padding: 16px 0;
  opacity: 0;
  outline: 0;
  height: 62px;
  display: block;
  transform: translateY(-62px);

  &:focus {
    position: relative;
    transform: translateY(0);
    width: 100%;
    left: 0;
    right: 0;
    opacity: 1;
  }
}
