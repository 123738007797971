@import '../../styles/breakpoints.scss';

.Root {
  position: relative;
  color: var(--color-grey-10);
  font-size: var(--font-size-body-4);
  z-index: 10;

  @media print {
    color: var(--color-black-800);
  }
}

.Container {
  grid-column: 2 / 6;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

.First .Link:focus-visible {
  margin-left: 3px;
}

.Crumb {
  position: relative;
  display: flex;
  gap: 8px;
  list-style-type: none;
  padding: 3px 0;
  overflow-x: scroll;
  padding-right: 40px;
  -webkit-mask-image: linear-gradient(to right, black 70%, transparent 100%);
  mask-image: linear-gradient(to right, black 70%, transparent 100%);
  scrollbar-color: transparent;
}
.Crumb::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  background-color: transparent;
}

.Crumb::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
}

.Crumb::-webkit-scrollbar-thumb {
  border-radius: 2px;
  height: 2px;
  background-color: var(--color-grey-10);
}

.ListItem {
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:after {
    content: '';
    margin-left: 8px;
    display: inline-block;
    background-image: url('/img/icon-chevron-right-white.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 8px;
    width: 6px;
  }
  &.Last:after {
    display: none;
  }
}

@media (min-width: $breakpoint-m) {
  .Container {
    grid-column: 2 / 14;
  }

  .Crumb {
    overflow-x: unset;
    -webkit-mask-image: unset;
    padding-right: 0;
    mask-image: unset;
    &::after {
      display: none;
    }
  }
}
