@import 'styles/breakpoints';

.Survey {
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 9998; // One below the Wagtail bird
  filter: drop-shadow(0 0 32px rgba(151, 151, 151, 0.2));

  &--Expanded {
    width: calc(100% - 40px);
    max-width: 428px;
  }

  &--Collapsed {
    width: 200px;
  }

  &__CloseButton {
    position: absolute;
    right: 0;
    top: 0;
    width: 42px;
    height: 42px;
    border: 0;
    background: transparent;
    padding: 0;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      margin: auto;

      width: 30px;
      height: 30px;
      border: 0;
      background-image: url('/img/icon-cross-black.svg');
      background-repeat: no-repeat;
      background-size: 26px;
      background-position: 5px 5px;
      border-radius: 50%;
    }
  }

  &__CollapsedButton {
    padding: 16px;
    font-size: 1.4rem;
    background-color: white;
    appearance: none;

    display: block;
    border: 0;
    width: 100%;
    text-align: left;
    font-family: var(--font-family-futura);
    font-size-adjust: 0.45;

    @media (min-width: $breakpoint-ml) {
      font-size: 1.6rem;
    }
  }

  &__Header {
    background-color: var(--color-white);
    padding: 16px 16px 4px 16px;
    font-size: 1.4rem;
  }

  &__Steps {
    text-align: center;
    font-size: 1.2rem;
    color: var(--color-grey-500);
    margin-left: auto;
    margin-right: auto;
  }

  &__HeaderStatus {
    font-size: 1.2rem;
    letter-spacing: 2.6px;
    text-transform: uppercase;
  }

  &__HeaderTitle {
    margin-top: 8px;
    font-weight: bold;

    @media (min-width: $breakpoint-ml) {
      font-size: 1.6rem;
    }
  }

  &__Content {
    background-color: var(--color-white);
    padding: 8px 16px 16px 16px;
  }

  &__ThankYouStep {
    text-align: center;
    padding: 16px 0;
  }

  &__ThankYouStepText {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 24px;
  }

  &__Nav {
    display: flex;
    margin-top: 27px;
    justify-content: space-between;
    align-items: center;
  }

  &__NavLeft {
    min-width: 60px;
  }
}
