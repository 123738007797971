@import '../../styles/breakpoints.scss';

.Root {
  &.Open {
    height: 100vh;
  }
}

.NavigationMobile {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-column: 2/6;
  padding: 16px 0;

  &.Open {
    display: none;
  }
}

.MegaNavigationMobile {
  padding: 16px 0;
  width: 100%;
  position: absolute;
  z-index: 100;
  top: 0;

  &.Open {
    display: none;
  }
}

.MegaNavWrap {
  grid-column: 2/6;
  display: flex;
  justify-content: space-between;
}

.LogoLink:focus-within img {
  outline: 2px solid #fff;
}

.Logo {
  position: relative;
  z-index: 65;
}

.Burger {
  background: transparent;
  border: none;
  position: relative;
  z-index: 60;
  padding-right: 0;

  .MegaNavWrap & {
    margin-bottom: 4px;
  }
}

.Dialog {
  outline: none;
  position: fixed;
  z-index: 50;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-black-800);
  opacity: 0;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.Container {
  grid-column: 2/6;
  height: 100%;
  padding-bottom: 130px;
}

.DialogContainer {
  grid-column: 2/6;
  position: relative;
  z-index: 51;
}
.Dialog .Burger {
  position: fixed;
  right: 16px;
  top: 12px;
}

.Dialog[data-enter] {
  opacity: 1;
}
.Dialog[data-leave] {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.TopBlob,
.LeftBlob {
  pointer-events: none;
  --gradient: url('/img/gradient-purple-blue.webp');

  &.PinkOrange {
    --gradient: url('/img/gradients/pink-orange--800p.webp');
  }

  &.PinkBlue {
    --gradient: url('/img/gradients/pink-blue--800p.webp');
  }

  &.PinkPurple {
    --gradient: url('/img/gradients/pink-purple--800p.webp');
  }

  &.BlueGreen {
    --gradient: url('/img/gradients/green-blue--800p.webp');
  }
}

.TopBlob {
  height: 150vh;
  background-image: var(--gradient);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  width: 200vw;
  position: fixed;
  top: -300px;
  left: -150px;
  z-index: 48;
}
.LeftBlob {
  display: none;
  height: 200vh;
  background-image: var(--gradient);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
  width: 200vw;
  position: fixed;
  top: 120px;
  right: -40px;
  z-index: 49;
}

.MegaMenuContact {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  .IsZoomed & {
    position: relative;
  }

  margin-top: auto;
  height: 130px;
  width: 100%;
  background: var(--gradient-purple-blue);

  &.PinkOrange {
    background: var(--gradient-pink-orange);
  }

  &.PinkBlue {
    background: var(--gradient-pink-blue);
  }

  &.PinkPurple {
    background: var(--gradient-pink-purple);
  }

  &.BlueGreen {
    background: var(--gradient-blue-green);
  }

  backdrop-filter: blur(10px);
  border-top: 1px solid;
  z-index: 61;
  border-image-slice: 1;
  border-width: 1px;
  display: flex;
  align-items: center;
  padding: 2.4rem 4rem;
  border-image-source: linear-gradient(
    113.59deg,
    rgba(255, 255, 255, 0.69) 7.33%,
    rgba(255, 243, 248, 0.5) 33.87%,
    rgba(255, 255, 255, 0) 50.55%,
    rgba(255, 255, 255, 0.15) 72.97%,
    rgba(255, 255, 255, 0.5) 99.02%
  );
}

.ContactTitle {
  font-size: var(--font-size-navigation-titles);
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
}

.ContactText {
  font-size: var(--font-size-nav-mobile-text);
}

.ContactWrap {
  margin-right: auto;
}

.DialogContainer {
  width: 100%;
}
.LinkContainer {
  grid-column: 2/6;
  margin-top: 120px;
  width: 100%;
}

.MegaMenuContainer {
  width: 100%;
}

.NavigationMobileLink,
.MegaMenuButton {
  color: #fff;
  font-size: var(--font-size-nav-mobile-link);
  font-weight: bold;
  text-transform: uppercase;
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 3.2rem;
  display: flex;
  gap: 16px;
  align-items: center;
  display: flex;
  align-items: center;
  width: 100%;
}

.MegaMenuMenuButton {
  color: #fff;
  font-size: var(--font-size-nav-mobile-back);
  text-transform: uppercase;
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  letter-spacing: 2.5px;
  gap: 8px;
  padding: 0;
  margin-bottom: 40px;
}

.Arrow {
  margin-left: auto;
}

.ArrowReverse {
  rotate: 180deg;
}

.MegaMenu {
  position: fixed;
  z-index: 55;
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: var(--color-black-800);
  opacity: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: opacity 0.5s ease;
  padding-top: 93px;
  top: 0;
  left: 0;
  pointer-events: none;

  &.IsOpen {
    pointer-events: initial;
    opacity: 1;
  }
}

.MegaMenuLink {
  display: block;
  font-size: var(--font-size-button-1);
  margin-bottom: 32px;
  margin-left: 32px;
}

@media (min-width: $breakpoint-m) {
  .Container {
    grid-column: 2/14;
  }

  .LinkContainer {
    grid-column: 2/14;
  }

  .MegaNavWrap {
    grid-column: 2/14;
  }

  .Burger {
    .MegaNavWrap & {
      margin-right: 16px;
    }
  }

  .NavigationMobile {
    grid-column: 2/14;
    padding: 1.6rem 1.6rem 1.6rem 0;
  }
}

.ImageInsightsWrapper {
  position: relative;
  overflow: hidden;
  aspect-ratio: 295 / 116; // taken from figma sketch, it's the image dimensions

  margin: 0 32px 32px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 1;
  }
}

.ImageContentInsights {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ImageTitleInsights {
  font-size: var(--font-size-subtitle);
  font-weight: bold;
  text-transform: uppercase;
  color: var(--grey-10);
  letter-spacing: 0.5px;
  margin-bottom: 16px;
  position: relative;
  padding-right: 32px;
}

.Icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.ImageTextInsights {
  font-size: var(--font-size-caption);
  line-height: 1.62;
}

.ImageLink {
  position: absolute;
  inset: 0;
}
