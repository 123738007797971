@import '../../styles/breakpoints.scss';

.Root {
  color: var(--color-grey-10);
  padding: 4rem 0;
  position: relative;
  background-color: var(--color-black-800);

  overflow: hidden;

  &.NoBg {
    background-color: transparent;
  }

  &.AllowOverflow {
    position: relative;
    overflow: visible;
  }

  @media print {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

.White {
  background-color: pink;
}
.Logo {
  width: 202px;
  height: 48px;
}

.LogoContainer {
  position: relative;
  z-index: 1;
  grid-column: 2/6;
  margin-bottom: 4.8rem;

  @media print {
    display: none;
  }
}
.Contact {
  position: relative;
  z-index: 1;
  grid-column: 2/6;
  margin-bottom: 4.8rem;
  font-size: var(--font-size-body-2);

  @media print {
    color: var(--color-black-800);
  }
}

.About {
  position: relative;
  z-index: 1;
  grid-column: 2/6;
  margin-bottom: 4.8rem;

  @media print {
    color: var(--color-black-800);
  }
}

.AboutItem {
  font-size: var(--font-size-body-2);
  color: var(--color-grey-10);
  background: transparent;
  border: none;
  padding: 0;
  margin-bottom: 2.4rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  @media print {
    color: var(--color-black-800);
  }
}
.Title {
  font-size: var(--font-size-micro-title);
  color: var(--color-grey-10);
  margin-bottom: 2.4rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @media print {
    color: var(--color-black-800);
  }
}

.ContactLinks {
  margin-top: 2.4rem;
}

.Email {
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 24px;

  &:before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 16px;
    background-image: url('/img/icon-email.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 2px;
  }

  @media print {
    display: none;
  }
}

.Phone {
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;

  &:before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('/img/icon-phone.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media print {
    display: none;
  }
}

.Adress {
}

.Name {
}
.Street {
}
.Zip {
}
.City {
}
.PlattanDesktop {
  display: none;
}

.Video {
  overflow: hidden;
  position: absolute;
  bottom: -360px;
  left: 0;
  right: 0;
  width: 100%;
  pointer-events: none;
  mix-blend-mode: screen;

  @media (min-width: $breakpoint-s) {
    bottom: -460px;
  }

  @media (min-width: $breakpoint-m) {
    bottom: 0;
    mix-blend-mode: unset;
  }

  -webkit-mask-image: linear-gradient(to top, black 90%, transparent 100%);
  mask-image: linear-gradient(to top, black 90%, transparent 100%);
}

.PlattanDesktop,
.PlattanMobile {
  width: 100%;
  transform: rotate(0.5turn) translateY(-33%);

  .BlendScreen & {
  }
}

.BlurbContainer {
  grid-column-start: 1;
  grid-column-end: 15;
  width: 100%;
}

.Blurbs {
  // aspect-ratio: 1;
  position: relative;
  background-repeat: no-repeat;
  background-position: center top;
  // background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  // left: 50%;
  z-index: -1;
  background-color: rgb(24, 24, 24);
  background-size: 100%;
  transform: rotate(0.5turn);
}

.Gradient {
  z-index: 0;
  pointer-events: none;
  --gradient: url('/img/gradient-purple-blue.webp');

  &.PinkOrange {
    --gradient: url('/img/gradients/pink-orange--800p.webp');
  }

  &.PinkBlue {
    --gradient: url('/img/gradients/pink-blue--800p.webp');
  }

  &.PinkPurple {
    --gradient: url('/img/gradients/pink-purple--800p.webp');
  }

  &.BlueGreen {
    --gradient: url('/img/gradients/green-blue--800p.webp');
  }

  position: absolute;
  background-image: var(--gradient);
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;

  .LogoContainer & {
    top: -120px;
    left: -250px;
    width: 1000px;
    height: 1000px;
    transform: rotate(90deg);
  }
}

@media (min-width: $breakpoint-m) {
  .PlattanDesktop,
  .PlattanMobile {
    transform: rotate(0.5turn) translateY(-50%);
  }
  .Logo {
    width: 228px;
    height: 54px;
  }
  .Root {
    padding: 10rem 0;
  }
  .PlattanDesktop {
    display: block;
  }
  .PlattanMobile {
    display: none;
  }
  .Blurbs {
    transform: translateY(-85px) translateZ(0);
    min-height: max(1600px, 103vh);
  }
  .Contact {
    font-size: var(--font-size-body-1);
  }
  .AboutItem {
    font-size: var(--font-size-body-1);
  }
}
@media (min-width: $breakpoint-s) {
}
@media (min-width: $breakpoint-m) {
}
@media (min-width: $breakpoint-ml) {
  .LogoContainer {
    grid-column: 2/5;
  }
  .Contact {
    grid-column: 6/9;
  }

  .About {
    grid-column: 10/14;
  }

  .Title {
    margin-bottom: 3.2rem;
  }
}
@media (min-width: $breakpoint-l) {
}
@media (min-width: $breakpoint-xl) {
}
