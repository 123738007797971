.Root {
  background-color: transparent;
  border: none;
}

.Burger {
  &.Force > * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
    scroll-behavior: auto !important;
  }
}

.BurgerLineTop {
  transform-origin: center;

  .Closed & {
    animation: moveTopLineAndRotateReverse 0.5s ease-in-out forwards;
  }
}

.BurgerLineMiddle {
  transition: opacity 0.1s ease-in-out 0.3s;
}

.BurgerLineBottom {
  transform-origin: center;
  .Closed & {
    animation: moveBottomLineAndRotateReverse 0.5s ease-in-out forwards;
  }
}

.Burger.Open .BurgerLineTop {
  animation: moveTopLineAndRotate 0.5s ease-in-out forwards;
}

.Burger.Open .BurgerLineMiddle {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.Burger.Open .BurgerLineBottom {
  animation: moveBottomLineAndRotate 0.5s ease-in-out forwards;
}

@keyframes moveTopLineAndRotate {
  0% {
    width: 30px;
    x: 70px;
    y: 10px;
  }
  50% {
    width: 90px;
    x: 5px;
    y: 45px;
    transform: rotate(0);
  }
  100% {
    width: 90px;
    x: 5px;
    y: 45px;
    transform: rotate(45deg);
  }
}

@keyframes moveBottomLineAndRotate {
  0% {
  }
  50% {
    y: 45px;
    transform: rotate(0);
  }
  100% {
    x: 5px;
    y: 45px;
    transform: rotate(-45deg);
  }
}

@keyframes moveTopLineAndRotateReverse {
  0% {
    width: 90px;
    x: 5px;
    y: 45px;
    transform: rotate(45deg);
  }
  50% {
    width: 90px;
    x: 5px;
    y: 45px;
    transform: rotate(0);
  }
  100% {
    width: 30px;
    x: 70px;
    y: 10px;
  }
}

@keyframes moveBottomLineAndRotateReverse {
  0% {
    x: 5px;
    y: 45px;
    transform: rotate(-45deg);
  }
  50% {
    y: 45px;
    transform: rotate(0);
  }
  100% {
  }
}
