.Root {
  width: 100%;
  position: relative;
  z-index: 500;
}

.FunFact {
  opacity: 0;
  transition: opacity 1.2s ease 0.6s;

  &.FunFactVisible {
    transition: opacity 1.2s ease 0.2s;
    opacity: 1;
  }
}

.NavigationDesktop {
  display: flex;
  gap: 3.2rem;
  align-items: center;
  height: 88px;
  grid-column-start: 2;
  grid-column-end: 14;
}

.ImageInsightsWrapper {
  position: relative;
  overflow: hidden;
  aspect-ratio: 16 / 9;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 1;
  }

  opacity: 0;
  transform: translateY(60px);
  transition: opacity 1.5s ease 0.3s, transform 1s ease 0.1s;

  [data-enter] & {
    transform: translateY(0);
    opacity: 1;
  }
}

.ImageInsights {
}

.ImageContent {
  margin-top: 80px;
  height: 100%;
  position: relative;
  grid-column-start: 1;
  grid-column-end: 8;

  opacity: 0;
  transform: translateY(60px);
  transition: opacity 1.5s ease 0.3s, transform 1s ease 0.1s;

  [data-enter] & {
    transform: translateY(0);
    opacity: 1;
  }
}

.ImageContentInsights {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ImageGlass {
  padding: 40px;
  position: absolute;
  right: 32px;
  bottom: 50px;
  max-width: 450px;
  width: 100%;
  opacity: 0;
  transition: opacity 1s ease, bottom 1.5s ease;

  &.Spotlight {
    opacity: 1;
    right: 32px;
    bottom: 140px;
  }

  .FunFactVisible & {
    right: 32px;
    bottom: 140px;
    opacity: 1;
  }
}

.ImageTagline {
  font-size: var(--font-size-label-1);
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 2.6px;
  margin-bottom: 16px;
}

.ImageTitle {
  font-size: var(--font-size-micro-title);
  font-weight: bold;
  text-transform: uppercase;
  color: var(--grey-10);
  letter-spacing: 0.5px;
  margin-bottom: 16px;
}

.ImageTitleInsights {
  font-size: var(--font-size-card-title);
  font-weight: bold;
  text-transform: uppercase;
  color: var(--grey-10);
  letter-spacing: 0.5px;
  margin-bottom: 16px;
}

.ImageText {
  font-size: var(--font-size-preamble);
  line-height: 1.62;
  margin-bottom: 16px;
}

.ImageTextInsights {
  font-size: var(--font-size-body-1);
  line-height: 1.62;
  margin-bottom: 16px;
  margin-bottom: 32px;
}

.ImageLink {
  position: relative;
  font-size: var(--font-size-body-1);

  &:after {
    content: '';
    height: 2px;
    width: 100%;
    display: block;
    position: absolute;
    bottom: -6px;
    left: 0;
    transition: opacity 1s ease;
    opacity: 1;
    background-image: var(--border-gradient);
  }

  &:before {
    margin-right: 12px;
    content: '';
    width: 18px;
    height: 14px;
    background-image: url('/img/icon-arrow-right-white.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
    display: inline-block;
    opacity: 1;
    transition: transform 0.5s ease;
  }

  &:hover:before {
    transform: translateX(4px);
  }
}

.NavigationDesktopLink {
  position: relative;
  display: inline-block;
}

.MegaMenu {
  height: 100%;
}

.MegaMenuButton {
  background-color: transparent;
  border: none;
  position: relative;
}

.MegaMenuButton:first-of-type {
  margin-left: auto;
}

.MegaMenuButton:after,
.NavigationDesktopLink:after {
  content: '';
  position: absolute;
  bottom: -5px;
  right: 0;
  left: unset;
  width: 0%;
  height: 2px;
  background-color: #fff;
  opacity: 0;
  transition: opacity 0s ease 1s, width 0.5s ease;
}

.MegaMenuButton.IsActive:after,
.NavigationDesktopLink.IsActive:after,
.MegaMenuButton:hover:after,
.NavigationDesktopLink:hover:after {
  right: unset;
  left: 0;
  width: 100%;
  opacity: 1;
  background-color: #fff;
  transition: width 1s ease;
}

.MegaMenuButtonOpen.MegaMenuButton:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: unset;
  width: 100%;
  height: 2px;
  background-color: #fff;
  opacity: 1;
}

.NavigationDesktopLink,
.MegaMenuButton {
  font-family: var(--font-family-futura);
  font-size: var(--font-size-navigation-titles);
  // letter-spacing: var(--letter-spacing-navigation-titles);
  color: var(--color-white);
  cursor: pointer;
  font-weight: 400;
}

.Chevron {
  width: 10px;
  height: 7px;
  background-image: url('/img/icon-chevron-down.svg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
  display: inline-block;
  margin-left: 8px;
  transition: transform var(--transition);
}

.ChevronOpen {
  transform: scaleY(-1);
}

.MegaMenuWrapper {
  --border-gradient: var(--gradient-purple-blue-hover);
  // purple
  --blob-gradient: url('/img/gradients/blue-purple--800p.webp');

  &.PinkOrange {
    --border-gradient: var(--gradient-pink-orange-hover);
    // pink
    --blob-gradient: url('/img/gradients/pink-orange--800p.webp');
  }

  &.PinkBlue {
    --border-gradient: var(--gradient-pink-blue-hover);
    --blob-gradient: url('/img/gradients/pink-blue--800p.webp');
  }

  &.GreenBlue {
    --border-gradient: var(--gradient-green-blue-hover);
    --blob-gradient: url('/img/gradients/green-blue--800p.webp');
  }

  &.PinkPurple {
    --border-gradient: var(--gradient-pink-purple-hover);
    --blob-gradient: url('/img/gradients/pink-purple--800p.webp');
  }

  z-index: 50;
  width: 100%;
  top: 9;
  height: 100vh;
  padding-top: 10rem;
  position: absolute;
  background-color: var(--color-black-800);
  opacity: 0;
  transition: opacity 0.7s ease;
  transition-delay: 0.4s;

  &[data-enter] {
    transition-delay: 0s;
    opacity: 1;
  }
}

.MegaMenu {
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
  // grid-gap: 2.4rem;
  transform: translate3d(0, 0, 0);
  overflow-y: scroll;
}

.MegaMenuItemsWrapper {
  margin-top: 80px;
  grid-column-start: 9;
  grid-column-end: 14;

  opacity: 0;
  transform: translateY(40px);
  transition: opacity 1.5s ease, transform 1s ease;

  &.Insights {
    margin-top: 48px;
  }

  [data-enter] & {
    transform: translateY(0);
    opacity: 1;
  }
}

.MegaMenuItem {
  display: block;
}

.MegaMenuItemWrapper {
  padding: 2px 0;
  margin-bottom: 2.7rem;
  overflow: hidden;
}

.MegaMenuLink {
  color: var(--color-white);
  font-family: var(--font-family-futura);
  font-size: var(--font-size-h3);
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  transform: translateX(-40px);
  display: inline-flex;
  gap: 16px;
  align-items: center;
  transition: transform 0.8s ease;

  &:after {
    content: '';
    height: 2px;
    width: 0%;
    display: block;
    position: absolute;
    bottom: -6px;
    right: 0;
    left: unset;
    transition: filter 1s ease, width 1s ease;
    background-image: var(--border-gradient);
    padding-bottom: 8px;
  }
  &:hover:after {
    width: 100%;
    left: 0;
    right: unset;
  }

  &:before {
    content: '';
    width: 28px;
    height: 22px;
    background-image: url('/img/icon-arrow-right-white.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
    display: inline-block;
    transition: opacity 1s ease;
  }

  &:hover {
    transform: translateX(0px);

    &:before,
    &:after {
    }
  }
}

.MegaMenuText {
  color: var(--color-white);
  font-family: var(--font-family-futura);
  font-size: var(--font-size-body-2);
  position: relative;
  opacity: 0;
  transition: opacity var(--transition);
}

.PurpleBlue.MegaMenuItemWrapper:before {
  background: linear-gradient(119.69deg, #de005d 14.73%, #0d7ede 103.38%);
}
.PinkOrange.MegaMenuItemWrapper:before {
  background: linear-gradient(118.57deg, #de005d 8.75%, #dd3800 110.67%);
}
.PinkBlue.MegaMenuItemWrapper:before {
  background: linear-gradient(118.57deg, #de005d 8.75%, #0d7ede 110.67%);
}

.MegaMenuItemWrapper:hover:before {
  opacity: 1;
}

.BlobRight1 {
  position: absolute;
  // top: -30vh;
  top: -15vh;
  right: -30vw;
  height: 120vh;
  width: 60vw;
  background-image: var(--blob-gradient);
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate3d(0, 0, 0);

  opacity: 0;
  transition: opacity 1.5s ease;

  [data-enter] & {
    opacity: 0.7;
  }
}

.BlobLeft1 {
  position: absolute;
  top: -45vh;
  left: -30vw;
  height: 100vh;
  width: 100vw;
  background-image: var(--blob-gradient);
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate3d(0, 0, 0);

  opacity: 0;
  transition: opacity 1.5s ease;

  [data-enter] & {
    opacity: 0.7;
  }
}

.BlobCenter1 {
  position: absolute;
  // top: -30vh;
  bottom: -25vh;
  left: 20vw;
  height: 100vh;
  width: 80vw;
  background-image: var(--blob-gradient);
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  transition: opacity 1.5s ease;
  transform: translate3d(0, 0, 0);

  [data-enter] & {
    opacity: 0.7;
  }
}

.LeftColumn {
  margin-top: 68px;
  grid-column-start: 2;
  grid-column-end: 8;
  // background-color: red;
}

.RightColumn {
  margin-top: 68px;
  grid-column-start: 9;
  grid-column-end: 14;
}

.MegaMenuInsightsTitle {
  font-size: var(--font-size-label-1);
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 2.6px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-white);

  opacity: 0;
  transform: translateY(40px);
  transition: opacity 1.5s ease, transform 1s ease;

  [data-enter] & {
    transform: translateY(0);
    opacity: 1;
  }
}

.MegaMenuCloseButton {
  cursor: pointer;
  text-decoration: underline;
  font-size: var(--font-size-button-3);
  color: var(--color-white);
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;

  position: absolute;
  right: 0;
  top: 2.4rem;
  grid-column-end: 14;

  opacity: 0;
  transform: translateY(40px);
  transition: opacity 1.5s ease, transform 1s ease;

  [data-enter] & {
    transform: translateY(0);
    opacity: 1;
  }

  &:hover {
    text-decoration: none;
  }
}
