@import '../../styles/breakpoints.scss';
.Root {
  transition: background-color 0.25s ease-in-out;
  // &::after {
  //   transition: opacity 0.25s ease-in-out;
  //   top: 0;
  //   left: 0;
  //   background-color: white;
  //   position: absolute;
  //   height: 100%;
  //   width: 100%;
  //   opacity: 0;
  //   content: '';
  // }
}

.BasePageWrap {
  &.WhoWeArePage {
    overflow: hidden;
  }
}

.NavMobile {
}

.NavDesktop {
  display: none;
}

.FooterHr {
  position: relative;
  width: 100%;
  height: 1px;

  &:before {
    display: block;
    content: '';

    height: 1px;
    width: auto;

    opacity: 0.5;
    background-color: #6b6b6b;

    grid-column-start: 2;
    grid-column-end: 6;

    @media (min-width: $breakpoint-m) {
      grid-column-start: 2;
      grid-column-end: 14;
    }

    @media (min-width: $breakpoint-l) {
      grid-column-start: 2;
      grid-column-end: 14;
    }
  }
}

@media (min-width: $breakpoint-m) {
  .Root {
  }
}

@media (min-width: $breakpoint-ml) {
  .NavMobile {
    display: none;
  }
  .NavDesktop {
    display: block;
  }
}

.Root.White {
  transition: background-color 0.25s ease-in-out;
  background-color: #fff;
  // &::after {
  //   opacity: 1;
  // }

  *:focus-visible {
    outline-color: var(--color-focus-white);
  }
}
