@import '../../styles/breakpoints.scss';

.Root {
  --color: var(--color-white);
  --size: var(--font-size-button-2);
  --ButtonSize: 4.8rem;
}

.Root.XSmall {
  --size: var(--font-size-body-3);
  --ButtonSize: 3rem;
}

.Root.Small {
  --size: var(--font-size-button-2);
}

.Root.Large {
  --size: var(--font-size-button-1);
  --ButtonSize: 5.8rem;
}

.Root.Black {
  --color: var(--color-black);
}

.Root.Black .IconWrap {
  --border-background: linear-gradient(
    106.95deg,
    #000 13.24%,
    rgba(255, 255, 255, 0) 29.86%,
    rgba(255, 255, 255, 0) 60.25%,
    #000 86.44%
  );
}

.Root.SlideShowNav .Icon {
  width: 12px;
  height: 12px;
}

.Root.XSmall .Icon {
  width: 12px;
  height: 12px;
}

.Root.SlideShowNavWhite .IconWrap {
  --border-background: linear-gradient(
    106.95deg,
    #000 13.24%,
    rgba(255, 255, 255, 0) 29.86%,
    rgba(255, 255, 255, 0) 60.25%,
    #000 86.44%
  );
}

.Root.StoriesModal {
  position: absolute;
  top: -40%;
  right: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  @media (min-width: $breakpoint-m) {
    top: -100px;
  }
}

.Root {
  display: inline-flex;
  z-index: 0;
  gap: 16px;
  color: var(--color);
  cursor: pointer;
  align-items: center;
  font-size: var(--size);
  background: none;
  border: none;
  letter-spacing: 0.3px;
}

.IconWrap {
  position: relative;
  // overflow: hidden;
  // background-image: var(--gradient-pink-blue);
  border-radius: 100px;
  border: none;
  height: var(--ButtonSize);
  width: var(--ButtonSize);
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 8px;
  align-items: center;
  --border: 1px;
  --radius: 100px;

  --border-background: linear-gradient(
    106.95deg,
    #ffffff 13.24%,
    rgba(255, 255, 255, 0) 29.86%,
    rgba(255, 255, 255, 0) 60.25%,
    #ffffff 86.44%
  );
  --border-background-hover: linear-gradient(
    293.99deg,
    #ffffff 10.27%,
    rgba(255, 255, 255, 0) 27.16%,
    rgba(255, 255, 255, 0) 58.06%,
    #ffffff 84.68%
  );

  .Disabled & {
    background-image: linear-gradient(
      119.69deg,
      #434343 14.73%,
      #7b7b7b 103.38%
    );
  }

  .SlideShowNavWhite.Disabled & {
    background-image: none;
  }
}

.Root:hover:before {
  opacity: 0;
}
.Root.Active:after,
.Root:hover:after {
  opacity: 1;
}

.IconWrap:before {
  content: '';
  display: block;
  // background-image: var(--gradient-pink-blue);
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  transition: opacity 0.8s ease;
}

.IconWrap:after {
  content: '';
  display: block;
  // background-image: var(--gradient-pink-blue-hover);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  transition: opacity 0.8s ease;
}

.Icon {
  //hack to change color of svg
  .Disabled & {
    filter: brightness(0) saturate(100%) invert(84%) sepia(27%) saturate(0%)
      hue-rotate(250deg) brightness(90%) contrast(90%);
  }

  .SlideShowNavWhite.Disabled & {
    filter: invert(46%) sepia(0%) saturate(1070%) hue-rotate(235deg)
      brightness(103%) contrast(74%);
  }
}

.Root.IconHoverDown .Icon {
  transition: transform 0.2s ease;
}

.Root.IconHoverDown:hover .Icon {
  transform: translateX(5px);
}
.Root.NoPadding {
  padding: 0;
}
@media (min-width: $breakpoint-m) {
  .IconRotate45 .Icon {
    rotate: 90deg;
  }
}
.NoPadding {
  padding: 0;
}
.IconWrap.Circle {
  padding: 1.5rem;
}

.InlineFlex {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}

.Bg {
  content: '';
  position: absolute;
  z-index: 0;
  inset: 0;
  border-radius: var(--radius);
  padding: var(--border); /* the thickness of the border */
  /* the below will do the magic */
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
    /* this will cover only the content area (no padding) */
      linear-gradient(#fff 0 0); /* this will cover all the area */
  -webkit-mask-composite: xor; /* needed for old browsers until the below is more supported */
  mask-composite: exclude; /* this will exclude the first layer from the second so only the padding area will be kept visible */
}

.Bg:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 110%;
  height: 200%;
  background: var(--border-background);
  transform: translate(-46%, -50%) rotate(8deg);
  transition: transform 0.9s ease;

  .Disabled & {
    background: none;
  }

  .SlideShowNavWhite.Disabled & {
    background: linear-gradient(
      106.95deg,
      #181818 13.24%,
      rgba(24, 24, 24, 0) 29.86%,
      rgba(24, 24, 24, 0) 60.25%,
      #181818 86.44%
    );
    opacity: 0.3;
  }
}

.Root:not(.Disabled):hover .Bg:before,
.Root:not(.Disabled).Active .Bg:before {
  transform: translate(-50%, -50%) rotate(187deg);
}

.Disabled.Root {
  --border-background: var(--color-grey-500);
  --border-background-hover: var(--color-grey-500);
  --color: var(--color-grey-500);
  cursor: default;
}
